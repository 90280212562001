import { Component, Injector, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CompaniesServiceProxy, CompanyDto, IntradayDataServiceProxy } from "@shared/service-proxies/service-proxies";
import { forkJoin } from "rxjs";
import { CompanyStatusItem } from "@app/main/intraday/components/my-offers/my-offers.model";

export class BreadcrumbItem {
    text: string;
    routerLink?: string;
    navigationExtras?: NavigationExtras;

    constructor(text: string, routerLink?: string, navigationExtras?: NavigationExtras) {
        this.text = text;
        this.routerLink = routerLink;
        this.navigationExtras = navigationExtras;
    }

    isLink(): boolean {
        return !!this.routerLink;
    }
}

@Component({
    selector: 'sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent extends AppComponentBase {
    @Input() title: string;
    @Input() description: string;
    @Input() breadcrumbs: BreadcrumbItem[];

    constructor(
        private _router: Router, injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    goToBreadcrumb(breadcrumb: BreadcrumbItem): void {
        if (!breadcrumb.routerLink) {
            return;
        }

        if (breadcrumb.navigationExtras) {
            this._router.navigate([breadcrumb.routerLink], breadcrumb.navigationExtras);
        } else {
            this._router.navigate([breadcrumb.routerLink]);
        }
    }
}

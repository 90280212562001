import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.CounterpartiesServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.CustomerNominationsServiceProxy,
        ApiServiceProxies.CompaniesServiceProxy,
        ApiServiceProxies.PowerPlantsServiceProxy,
        ApiServiceProxies.InputMastersServiceProxy,
        ApiServiceProxies.CustomerAvailabilitiesServiceProxy,
        ApiServiceProxies.CustomersServiceProxy,
        ApiServiceProxies.ForecastPricesServiceProxy,
        ApiServiceProxies.HourlyBidsAppServiceProxy,
        ApiServiceProxies.CustomerBilateralContractsServiceProxy,
        ApiServiceProxies.NominationsServiceProxy,
        ApiServiceProxies.AvailabilityServiceProxy,
        ApiServiceProxies.MepServiceProxy,
        ApiServiceProxies.AlertRulesServiceProxy,
        ApiServiceProxies.BilateralContractsServiceProxy,
        ApiServiceProxies.MarketGroupsServiceProxy,
        ApiServiceProxies.FinalDailyGenerationsServiceProxy,
        ApiServiceProxies.HourlyBidResultsServiceProxy,
        ApiServiceProxies.CellLockServiceProxy,
        ApiServiceProxies.DomainServiceProxy,
        ApiServiceProxies.CustomerFlexiblePositionsServiceProxy,
        ApiServiceProxies.FlexiblePositionsServiceProxy,
        ApiServiceProxies.CustomerConsumptionForecastForecastsServiceProxy,
        ApiServiceProxies.ConsumptionForecastsServiceProxy,
        ApiServiceProxies.CustomerNetBilateralContractManagementsServiceProxy,
        ApiServiceProxies.NetBilateralContractManagementsServiceProxy,
        ApiServiceProxies.IntradayOfferServiceProxy,
        ApiServiceProxies.IntradayDataServiceProxy,
        ApiServiceProxies.IntradayBotsServiceProxy,
        ApiServiceProxies.IntradayBotSchedulerServiceProxy,
        ApiServiceProxies.IntradayBotLibrariesServiceProxy,
        ApiServiceProxies.OperationBotsServiceProxy,
        ApiServiceProxies.CustomerDayAheadMarketManagementsServiceProxy,
        ApiServiceProxies.DayAheadMarketManagementsServiceProxy,
        ApiServiceProxies.CompanyProviderSecretNameServiceProxy,
        ApiServiceProxies.DayAheadJobPlanningServiceProxy,
        ApiServiceProxies.IntradayWithinGroupSettingServiceProxy,
        ApiServiceProxies.FinalDailyGenerationRevisionServiceProxy,
        ApiServiceProxies.ProviderSynchronizationServiceProxy,
        ApiServiceProxies.ValueSourcesServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}

<label class="form-label" *ngIf="LabelToLocalize" [for]="Id">{{ LabelToLocalize | localize }}</label>

<div class="input-wrapper" (click)="inputWrapperClick()">
    <input bsDatepicker
           autocomplete="off"
           class="form-control"
           type="text"
           [class.virtual-disabled]="InputVirtuallyDisabled"
           [disabled]="Disabled"
           [bsConfig]="bsConfig"
           (ngModelChange)="onDateChange($event)"
           [(ngModel)]="_selectedDate"
           [minDate]="MinDate"
           [maxDate]='MaxDate'
           [id]="Id"
           [name]="Id"
           [required]="IsRequired">

    <div class="calendar-icon" *ngIf="showCalendarIcon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.32" fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.12411 8H21.8758C21.9467 9.20109 22 10.8266 22 13C22 16.4843 21.8631 18.5605 21.7362 19.7371C21.6422 20.6088 21.0518 21.281 20.1903 21.4442C18.8494 21.6983 16.3428 22 12 22C7.65725 22 5.15055 21.6983 3.80967 21.4442C2.94826 21.281 2.35785 20.6088 2.26379 19.7371C2.13682 18.5605 2 16.4843 2 13C2 10.8266 2.05324 9.20109 2.12411 8ZM6.5 12.6815C6.5 12.0806 6.93315 11.6082 7.5337 11.5868C8.3136 11.559 9.6669 11.5275 12 11.5069C14.5069 11.4848 15.8826 11.5197 16.6312 11.5599C17.1554 11.5881 17.5 11.9946 17.5 12.5196V12.9107C17.5 13.4726 17.1157 13.9093 16.5543 13.934C15.7898 13.9675 14.4235 14 12 14C9.5765 14 8.21025 13.9675 7.44565 13.934C6.88435 13.9093 6.5 13.4726 6.5 12.9107V12.6815ZM6.5 17.0891C6.5 16.5276 6.8844 16.0793 7.44515 16.0503C7.97275 16.0231 8.7795 16.0001 10 16.0001C11.2205 16.0001 12.0272 16.0231 12.5548 16.0503C13.1156 16.0793 13.5 16.5276 13.5 17.0891V17.4111C13.5 17.9726 13.1156 18.4208 12.5548 18.4498C12.0272 18.4771 11.2205 18.5001 10 18.5001C8.7795 18.5001 7.97275 18.4771 7.44515 18.4498C6.8844 18.4208 6.5 17.9726 6.5 17.4111V17.0891Z"
                  fill="#637381"/>
            <path
                d="M17.915 2.03126C18.2276 2.05703 18.4424 2.28598 18.4636 2.59889C18.4831 2.88636 18.5 3.32917 18.5 4C18.5 4.10776 18.4995 4.20964 18.4987 4.30593C19.1999 4.38632 19.7561 4.47356 20.1902 4.55581C21.0516 4.71901 21.642 5.39115 21.7361 6.26285C21.7839 6.7055 21.833 7.27555 21.8758 8H2.12402C2.16677 7.27555 2.21593 6.7055 2.2637 6.26285C2.35776 5.39115 2.94817 4.71902 3.80958 4.55581C4.24369 4.47356 4.8 4.3863 5.50125 4.30591C5.50045 4.20962 5.5 4.10775 5.5 4C5.5 3.32917 5.5169 2.88636 5.5364 2.59889C5.5576 2.28598 5.7724 2.05703 6.08495 2.03126C6.29765 2.01372 6.5938 2 7 2C7.4062 2 7.70235 2.01372 7.91505 2.03126C8.2276 2.05703 8.4424 2.28598 8.4636 2.59889C8.4831 2.88636 8.5 3.32917 8.5 4C8.5 4.02539 8.5 4.05047 8.49995 4.07521C9.50645 4.02844 10.6666 4 11.9999 4C13.3332 4 14.4935 4.02845 15.5 4.07522L15.5 4C15.5 3.32917 15.5169 2.88636 15.5364 2.59889C15.5576 2.28598 15.7724 2.05703 16.0849 2.03126C16.2976 2.01372 16.5938 2 17 2C17.4062 2 17.7023 2.01372 17.915 2.03126Z"
                fill="#637381"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.5337 11.5868C6.93315 11.6081 6.5 12.0805 6.5 12.6815V12.9107C6.5 13.4725 6.88435 13.9093 7.44565 13.9339C8.21025 13.9674 9.5765 14 12 14C14.4235 14 15.7897 13.9674 16.5543 13.9339C17.1156 13.9093 17.5 13.4725 17.5 12.9107V12.5196C17.5 11.9946 17.1554 11.5881 16.6312 11.5599C15.8825 11.5196 14.5069 11.4847 12 11.5069C9.6669 11.5274 8.3136 11.559 7.5337 11.5868ZM7.44515 16.0503C6.8844 16.0793 6.5 16.5275 6.5 17.089V17.411C6.5 17.9725 6.8844 18.4208 7.44515 18.4498C7.97275 18.477 8.7795 18.5 10 18.5C11.2205 18.5 12.0272 18.477 12.5548 18.4498C13.1156 18.4208 13.5 17.9725 13.5 17.411V17.089C13.5 16.5275 13.1156 16.0793 12.5548 16.0503C12.0272 16.023 11.2205 16 10 16C8.7795 16 7.97275 16.023 7.44515 16.0503Z"
                  fill="#637381"/>
        </svg>
    </div>
</div>


<button
    *ngIf="Clearable"
    (click)="onClearClicked()"
    class="btn btn-xs btn-outline-danger bs-datepicker-clear"
    title="Temizle">
    <i class="fas fa-ban"></i>
</button>

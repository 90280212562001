import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SubHeaderComponent } from './sub-header.component';
import { BsDropdownModule } from "@node_modules/ngx-bootstrap/dropdown";
import { UtilsModule } from "@shared/utils/utils.module";

@NgModule({
    imports: [CommonModule, BsDropdownModule, UtilsModule],
    declarations: [SubHeaderComponent],
    exports: [SubHeaderComponent],
})
export class SubheaderModule {}

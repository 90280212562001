<div>
    <div [class]="isAddingSectionOpened ? 'justify-content-between' : 'gap-5'" class="d-flex align-items-center w-100">
        <div class="form-group">
            <custom-select-container
                #DayOfWeekTimeSelections
                [items]="dayOfWeekTimeSelections"
                (onItemRemoved)="onDayOfWeekTimeRemoved($event)"
                [required]="required"
                [disabled]="disabled"
                buttonClassName="w-250px"
                [id]="id + 'DayOfWeekTimeList'">
            </custom-select-container>
        </div>
        <div *ngIf="!isAddingSectionOpened" (click)="openAddingSection()" [custom-tooltip]="l('AddNew')" [class]="disabled ? 'pe-none' : ''" class="grey-icon-container cursor-pointer p-2">
            <img src="assets/common/icons/plus.svg" class="icon h-20px w-20px" alt="icons_plus"/>
        </div>
        <div *ngIf="isAddingSectionOpened" class="form-group labeled-select-group pb-5">
            <label [class.invalid]="isTouchedAndInvalid(DayOfWeek)" class="form-label select-label m-0" for="DayOfWeekSelect">
                <span class="fs-8 px-1">{{l("DayOfWeek")}}</span>
            </label>
            <select #DayOfWeek="ngModel" [(ngModel)]="dayOfWeek" class="form-select select-input" name="DayOfWeek" id="DayOfWeekSelect" required>
                <option *ngFor="let option of dayOfWeeks" [ngValue]="option.value" [selected]="dayOfWeek === option.value">{{option.label}}</option>
            </select>
        </div>
        <div *ngIf="isAddingSectionOpened" class="form-group">
            <custom-moment-time-picker [(hours)]="hour" [(minutes)]="minute" [isNullable]="false"></custom-moment-time-picker>
        </div>
        <div *ngIf="isAddingSectionOpened" (click)="add()" [custom-tooltip]="l('Add')" class="grey-icon-container cursor-pointer p-2">
            <img src="assets/common/icons/checked.svg" class="icon h-20px w-20px" alt="icons_checked"/>
        </div>
        <div *ngIf="isAddingSectionOpened" (click)="closeAddingSection()" [custom-tooltip]="l('Cancel')" class="grey-icon-container cursor-pointer p-2">
            <img src="assets/common/icons/cross.svg" class="icon h-20px w-20px" alt="icons_cross"/>
        </div>
    </div>
</div>

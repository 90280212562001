import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as sh from '@shared/helpers/StringHelper';
import { CustomTooltipPosition } from '@shared/utils/tooltip/CustomTooltipPosition';
import { CustomTooltipTheme } from '@shared/utils/tooltip/CustomTooltipTheme';

@Component({
    selector: 'custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent extends AppComponentBase implements OnInit {

    public shouldBeLocalized: boolean = false;
    public position: CustomTooltipPosition = CustomTooltipPosition.DYNAMIC;
    public theme: CustomTooltipTheme = CustomTooltipTheme.DEFAULT;
    public contentString: string;
    public left: number = 0;
    public top: number = 0;
    public containerClassName: string = "fs-8 fw-semibold";
    public visible = false;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        if (!sh.isNullOrEmpty(this.contentString)) {
            if (this.shouldBeLocalized) {
                this.contentString = this.l(this.contentString);
            }
        }
    }
}

export class MinLengths
{
    public static Text: number = 1;
    public static TinyText: number = 1;
    public static SmallText: number = 1;
    public static ShortText: number = 1;
    public static LongText: number = 1;
    public static DescriptiveText: number = 1;
    public static FormulaText: number = 1;
}

export class MaxLengths
{
    public static Text: number = 256;
    public static TinyText: number = 32;
    public static SmallText: number = 64;
    public static ShortText: number = 128;
    public static LongText: number = 512;
    public static DescriptiveText: number = 1024;
    public static FormulaText: number = 2048;
}

import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DayOfWeek, DayOfWeekTime } from '@shared/service-proxies/service-proxies';
import { CustomSelectComponent, ICustomSelectItem } from '@app/shared/common/custom-select/custom-select.component';
import * as oh from '@shared/helpers/ObjectHelper';
import * as _ from 'lodash';

@Component({
    selector: 'custom-day-of-week-time-select',
    templateUrl: './custom-day-of-week-time-select.component.html',
    styleUrls: ['./custom-day-of-week-time-select.component.scss'],
    animations: [appModuleAnimation()]
})
export class CustomDayOfWeekTimeSelectComponent extends AppComponentBase {

    protected readonly DayOfWeek = DayOfWeek;

    @ViewChild("DayOfWeekTimeSelections") dayOfWeekTimeSelectionsSelectComponent: CustomSelectComponent;

    @Input() isMultiple: boolean = true;
    @Input() dayOfWeekTimes: DayOfWeekTime[] = [];
    @Output() dayOfWeekTimesChange: EventEmitter<DayOfWeekTime[]> = new EventEmitter<DayOfWeekTime[]>();

    @Input() id: string;
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;

    dayOfWeeks: ICustomSelectItem<DayOfWeek>[] = [
        { label: this.l("MondayAbbreviation"), value: DayOfWeek.Monday },
        { label: this.l("TuesdayAbbreviation"), value: DayOfWeek.Tuesday },
        { label: this.l("WednesdayAbbreviation"), value: DayOfWeek.Wednesday },
        { label: this.l("ThursdayAbbreviation"), value: DayOfWeek.Thursday },
        { label: this.l("FridayAbbreviation"), value: DayOfWeek.Friday },
        { label: this.l("SaturdayAbbreviation"), value: DayOfWeek.Saturday },
        { label: this.l("SundayAbbreviation"), value: DayOfWeek.Sunday }
    ];
    dayOfWeekTimeSelections: ICustomSelectItem<DayOfWeekTime>[] = [];
    isAddingSectionOpened: boolean = false;
    dayOfWeek: DayOfWeek = this.dayOfWeeks[0].value;
    hour: number = 0;
    minute: number = 0;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (!oh.isNullOrEmpty(this.dayOfWeekTimes)) {
            this.dayOfWeekTimeSelections = this.dayOfWeekTimes.map(dayOfWeekTime => {
                let hourText = dayOfWeekTime.hour < 10 ? `0${dayOfWeekTime.hour}` : `${dayOfWeekTime.hour}`;
                let minuteText = dayOfWeekTime.minute < 10 ? `0${dayOfWeekTime.minute}` : `${dayOfWeekTime.minute}`;
                return <ICustomSelectItem<DayOfWeekTime>>{
                    label: `${this.l(DayOfWeek[dayOfWeekTime.dayOfWeek])} ${hourText}:${minuteText}`,
                    value: dayOfWeekTime
                };
            });
        } else {
            this.dayOfWeekTimes = [];
        }
    }

    reset() {
        this.dayOfWeek = this.dayOfWeeks[0].value;
        this.hour = 0;
        this.minute = 0;
    }

    onDayOfWeekTimeAdded(item: ICustomSelectItem<DayOfWeekTime>) {
        if (oh.isNullOrUndefined(item.value.hour) || oh.isNullOrUndefined(item.value.minute) || isNaN(item.value.hour) || isNaN(item.value.minute)) {
            return;
        }

        let existingElement = this.dayOfWeekTimeSelections.find(x => x.value.dayOfWeek === item.value.dayOfWeek && x.value.hour === item.value.hour && x.value.minute === item.value.minute);
        if (oh.isNullOrUndefined(existingElement)) {

            let hourText = item.value.hour < 10 ? `0${item.value.hour}` : `${item.value.hour}`;
            let minuteText = item.value.minute < 10 ? `0${item.value.minute}` : `${item.value.minute}`;
            item.label = `${this.l(DayOfWeek[item.value.dayOfWeek])} ${hourText}:${minuteText}`;

            this.dayOfWeekTimeSelections.push(item);
            this.dayOfWeekTimeSelections = _.orderBy(this.dayOfWeekTimeSelections, ["value.dayOfWeek", "value.hour", "value.minute"], ["asc", "asc", "asc"]);
        }

        this.setDayOfWeekTimes();
    }

    onDayOfWeekTimeRemoved(item: ICustomSelectItem<DayOfWeekTime>) {
        let existingElement = this.dayOfWeekTimeSelections.find(x => x.value.dayOfWeek === item.value.dayOfWeek && x.value.hour === item.value.hour && x.value.minute === item.value.minute);
        if (!oh.isNullOrUndefined(existingElement)) {
            this.dayOfWeekTimeSelections = this.dayOfWeekTimeSelections.filter(x => x !== existingElement);
        }

        this.setDayOfWeekTimes();
    }

    setDayOfWeekTimes() {
        this.dayOfWeekTimes = this.dayOfWeekTimeSelections.map(x => x.value);
        this.dayOfWeekTimesChange.emit(this.dayOfWeekTimes);
    }

    openAddingSection() {
        this.isAddingSectionOpened = true;
        this.reset();
    }

    closeAddingSection() {
        this.isAddingSectionOpened = false;
        this.reset();
    }

    add() {
        if (!oh.isNullOrUndefined(this.dayOfWeek) && !oh.isNullOrUndefined(this.hour) && !oh.isNullOrUndefined(this.minute)) {
            this.onDayOfWeekTimeAdded({
                label: `${this.l(DayOfWeek[this.dayOfWeek])} ${this.hour}:${this.minute}`,
                value: new DayOfWeekTime({
                    dayOfWeek: this.dayOfWeek,
                    hour: this.hour,
                    minute: this.minute
                })
            });

            this.closeAddingSection();
        }
    }

    public markAsTouched() {
        if (!oh.isNullOrUndefined(this.dayOfWeekTimeSelectionsSelectComponent)) {
            this.dayOfWeekTimeSelectionsSelectComponent.isTouched = true;
        }
    }
}

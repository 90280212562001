import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as oh from '@shared/helpers/ObjectHelper';
import * as sh from '@shared/helpers/StringHelper';
import { ICustomSelectItem } from '@app/shared/common/custom-select/custom-select.component';

@Component({
    selector: 'custom-select-container',
    templateUrl: './custom-select-container.component.html',
    styleUrls: ['./custom-select-container.component.scss'],
    animations: [appModuleAnimation()]
})
export class CustomSelectContainerComponent extends AppComponentBase implements OnInit {

    @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onItemRemoved: EventEmitter<ICustomSelectItem> = new EventEmitter<ICustomSelectItem>();

    @Input() id: string = "";
    @Input() buttonClassName: string;
    @Input() isButtonIconLeft: boolean = false;
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;

    @Input() items: ICustomSelectItem[] = [];

    isTouched: boolean = false;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (!sh.isNullOrEmpty(this.buttonClassName) && this.buttonClassName.indexOf(" touched") === -1) {
            this.buttonClassName = this.buttonClassName + " touched";
        }
    }

    getButtonText() {
        if (oh.isNullOrEmpty(this.items)) {
            return "0 " + this.l("Selected");
        } else if(this.items.length === 1) {
            return this.items[0].label;
        } else {
            return this.items.length + " " + this.l("Selected");
        }
    }

    getButtonTextClass() {
        if (this.required && this.isTouched && oh.isNullOrEmpty(this.items)) {
            return "text-danger";
        } else {
            return "";
        }
    }

    getButtonIconSrc() {
        if (this.required && this.isTouched && oh.isNullOrEmpty(this.items)) {
            return "/assets/common/icons/collapse-down-red.svg";
        } else {
            return "/assets/common/icons/collapse-down-dark.svg";
        }
    }

    select(item: ICustomSelectItem) {
        let index = this.items.findIndex(x => x.value === item.value);
        if (index !== -1) {
            this.items.splice(index, 1);
            this.onItemRemoved.emit(item);
        }
    }

    open() {
        if (this.buttonClassName && this.buttonClassName.indexOf("touched") === -1) {
            this.buttonClassName += " touched";
        }

        let containerElement = document.getElementById(this.id + "CustomSelectContainer");
        if (containerElement) {
            containerElement.focus();
            let menuElements = containerElement.getElementsByClassName("custom-select-menu");
            if (menuElements && menuElements.length > 0) {
                for (let i = 0; i < menuElements.length; i++) {
                    let menuElement = menuElements[i] as HTMLDivElement;
                    menuElement.style.visibility = "visible";
                    menuElement.style.opacity = "1";
                }

                this.onOpen.emit();
            }
        }
    }

    close() {
        let containerElement = document.getElementById(this.id + "CustomSelectContainer");
        if (containerElement) {
            let menuElements = containerElement.getElementsByClassName("custom-select-menu");
            if (menuElements && menuElements.length > 0) {
                (menuElements[0] as HTMLDivElement).style.visibility = "hidden";
                (menuElements[0] as HTMLDivElement).style.opacity = "0";
                this.isTouched = true;
                this.onClose.emit();
            }
        }
    }

    onFocusout() {
        setTimeout(() => {
            let activeElement = document.activeElement;
            if (activeElement) {
                let containerElement = document.getElementById(this.id + "CustomSelectContainer");
                if (containerElement) {
                    let menuElements = containerElement.getElementsByClassName("custom-select-menu");
                    if (menuElements && menuElements.length > 0) {
                        let menuElement = menuElements[0] as HTMLDivElement;
                        if (!menuElement.contains(activeElement)) {
                            this.close();
                        }
                    }
                }
            }
        }, 100);
    }
}

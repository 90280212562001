import { ApplicationRef, ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Injector, Input, OnInit, ViewContainerRef } from '@angular/core';
import { AppDirectiveBase } from '@shared/common/app-directive-base';
import { CustomTooltipComponent } from '@shared/utils/tooltip/component/custom-tooltip.component';
import { CustomTooltipPosition } from '@shared/utils/tooltip/CustomTooltipPosition';
import { CustomTooltipTheme } from '@shared/utils/tooltip/CustomTooltipTheme';
import * as oh from '@shared/helpers/ObjectHelper';

@Directive({
    selector: '[custom-tooltip]',
})
export class CustomTooltipDirective extends AppDirectiveBase implements OnInit {

    @Input('custom-tooltip') contentString: string;
    @Input() position: CustomTooltipPosition = CustomTooltipPosition.DYNAMIC;
    @Input() theme: CustomTooltipTheme = CustomTooltipTheme.DEFAULT;
    @Input() showDelay = 0;
    @Input() hideDelay = 0;
    @Input() shouldBeLocalized?: boolean = false;
    @Input() left: number = 0;
    @Input() top: number = 0;
    @Input() containerClassName: string;

    private _componentRef: ComponentRef<CustomTooltipComponent>;
    private showTimeout?: number;
    private hideTimeout?: number;
    private touchTimeout?: number;

    constructor(
        injector: Injector,
        private readonly _elementRef: ElementRef,
        private readonly _applicationRef: ApplicationRef,
        private readonly _viewContainerRef: ViewContainerRef
    ) {
        super(injector);
    }

    ngOnInit(): void { }

    @HostListener('mouseenter')
    onMouseEnter(): void {
        this.initializeTooltip();
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        this.setHideTooltipTimeout();
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove($event: MouseEvent): void {
        if (!oh.isNullOrUndefined(this.contentString) && !oh.isNullOrUndefined(this._componentRef) && this.position === CustomTooltipPosition.DYNAMIC) {
            this._componentRef.instance.left = $event.clientX;
            this._componentRef.instance.top = $event.clientY;
            this._componentRef.instance.contentString = this.contentString;
            this._componentRef.instance.containerClassName = !oh.isNullOrUndefined(this.containerClassName) ? this.containerClassName : this._componentRef.instance.containerClassName;
            this._componentRef.instance.shouldBeLocalized = !oh.isNullOrUndefined(this.shouldBeLocalized) ? this.shouldBeLocalized : this._componentRef.instance.shouldBeLocalized;
        }
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart($event: TouchEvent): void {
        $event.preventDefault();
        window.clearTimeout(this.touchTimeout);
        this.touchTimeout = window.setTimeout(this.initializeTooltip.bind(this), 500);
    }

    @HostListener('touchend')
    onTouchEnd(): void {
        window.clearTimeout(this.touchTimeout);
        this.setHideTooltipTimeout();
    }

    private initializeTooltip() {
        if (oh.isNullOrUndefined(this._componentRef)) {
            window.clearInterval(this.hideDelay);
            this._componentRef = this._viewContainerRef.createComponent(CustomTooltipComponent);

            const [tooltipDOMElement] = (this._componentRef.hostView as EmbeddedViewRef<any>).rootNodes;

            this.setTooltipComponentProperties();

            document.body.appendChild(tooltipDOMElement);
            this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);
        }
    }

    private setTooltipComponentProperties() {
        if (!oh.isNullOrUndefined(this.contentString) && !oh.isNullOrUndefined(this._componentRef)) {
            this._componentRef.instance.shouldBeLocalized = !oh.isNullOrUndefined(this.shouldBeLocalized) ? this.shouldBeLocalized : this._componentRef.instance.shouldBeLocalized;
            this._componentRef.instance.containerClassName = !oh.isNullOrUndefined(this.containerClassName) ? this.containerClassName : this._componentRef.instance.containerClassName;
            this._componentRef.instance.contentString = this.contentString;
            this._componentRef.instance.position = this.position;
            this._componentRef.instance.theme = this.theme;

            const {left, right, top, bottom} = this._elementRef.nativeElement.getBoundingClientRect();

            switch (this.position) {
                case CustomTooltipPosition.BOTTOM: {
                    this._componentRef.instance.left = Math.round((right - left) / 2 + left);
                    this._componentRef.instance.top = Math.round(bottom);
                    break;
                }
                case CustomTooltipPosition.TOP: {
                    this._componentRef.instance.left = Math.round((right - left) / 2 + left);
                    this._componentRef.instance.top = Math.round(top);
                    break;
                }
                case CustomTooltipPosition.RIGHT: {
                    this._componentRef.instance.left = Math.round(right);
                    this._componentRef.instance.top = Math.round(top + (bottom - top) / 2);
                    break;
                }
                case CustomTooltipPosition.LEFT: {
                    this._componentRef.instance.left = Math.round(left);
                    this._componentRef.instance.top = Math.round(top + (bottom - top) / 2);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    private showTooltip() {
        if (!oh.isNullOrUndefined(this.contentString) && !oh.isNullOrUndefined(this._componentRef)) {
            this._componentRef.instance.visible = true;
        }
    }

    private setHideTooltipTimeout() {
        this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    destroy(): void {
        if (!oh.isNullOrUndefined(this._componentRef)) {
            window.clearInterval(this.showTimeout);
            window.clearInterval(this.hideDelay);
            this._applicationRef.detachView(this._componentRef.hostView);
            this._componentRef.destroy();
            this._componentRef = undefined;
        }
    }
}
